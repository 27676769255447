// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { Form as BaseForm, setIn, getIn, StatusMessage } from '@plesk/ui-library';
import { forwardRef, useState, useEffect, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { redirect, redirectPost, api, showInternalError, escapeHtml } from 'jsw';
import { toFormData } from 'helpers/form';
import JswComponent from './JswComponent';
import render from '../../jsw/render';
import { useHistory } from 'react-router-dom';
import { isMatch } from './Link';

const clearMessages = root => {
    root.querySelectorAll('.field-errors').forEach(errors => {
        errors.style.display = 'none';
        errors.closest('.form-row')?.classList.remove('error');
        errors.querySelectorAll('.error-hint').forEach(error => {
            error.parentNode.removeChild(error);
        });
    });
};

const processFieldMessage = (root, prefix, name, message) => {
    let fieldErrors;
    const formElement = root.querySelector(`#${prefix.join('-')}`);
    fieldErrors = formElement ? formElement.parentNode.querySelector('.field-errors') : null;
    if (!fieldErrors) {
        fieldErrors = formElement ? formElement.closest('.form-row').querySelector('.field-errors') : null;
    }
    if (!fieldErrors) {
        fieldErrors = root.querySelector(`#${prefix.join('-')}-form-row`).querySelectorAll('.field-errors');
        fieldErrors = fieldErrors[fieldErrors.length - 1];
    }

    fieldErrors.closest('.form-row').classList.add('error');
    render(fieldErrors, `<span class="error-hint">${escapeHtml(message)}</span>`);
    fieldErrors.style.display = '';
};

const processFieldMessages = (root, messages, prefix) => {
    if (Array.isArray(messages)) {
        messages.forEach(message => {
            if (typeof message === 'string') {
                processFieldMessage(root, prefix, 'error', message);
            } else {
                prefix.push(name);
                processFieldMessages(root, message, prefix);
                prefix.pop();
            }
        });
    } else {
        Object.entries(messages).forEach(([key, value]) => {
            if (typeof value === 'string') {
                processFieldMessage(root, prefix, key, value);
            } else {
                prefix.push(key);
                processFieldMessages(root, value, prefix);
                prefix.pop();
            }
        });
    }
};

const findSubFormFields = (formId, subFormPrefix, callback) => {
    if (subFormPrefix.length === 0 || typeof callback !== 'function') {
        return;
    }
    const formData = new FormData([...document.forms].find(({ id }) => id === formId));
    for (const entry of formData.entries()) {
        const [key, value] = entry;
        if (subFormPrefix.any(prefix => key.startsWith(prefix))) {
            callback(key, value);
        }
    }
};

const getSubFormPrefixes = (formPrefix, embeddedForms) => embeddedForms.reduce((acc, { name }) => [
    ...acc,
    `${formPrefix}[${name}]`,
    name,
], []);

const setElementValue = (name, value) => {
    const elements = document.getElementsByName(name);
    elements.forEach(element => {
        if (element.type === 'checkbox' || element.type === 'radio') {
            element.checked = element.value === value;
        } else if (element.type === 'hidden' &&
            Array.prototype.filter.call(elements, ({ type }) => type === 'checkbox').length > 0) {
            // set only checkbox state
        } else {
            element.value = value;
        }
    });
};

const Form = forwardRef(({
    children,
    id,
    action,
    mutation,
    values,
    onFieldChange,
    onSubmit,
    onError,
    onSuccess,
    onLongTask,
    embeddedForms,
    formPrefix,
    embeddedFormsRender,
    ...props
}, ref) => {
    const history = useHistory();
    const innerRef = useRef(null);
    const [errors, setErrors] = useState({});
    const [statusMessages, setStatusMessages] = useState([]);
    const [state, setState] = useState(null);

    useEffect(() => {
        embeddedForms.forEach(({ name }) => {
            const form = document.getElementById(`embedded-form-${name}`);
            clearMessages(form);

            const subFormErrors = (formPrefix ? errors[formPrefix] || {} : errors)[name] || {};
            processFieldMessages(form, subFormErrors, formPrefix ? [formPrefix, name] : [name]);
        });
    }, [errors, embeddedForms, formPrefix]);

    useEffect(() => {
        findSubFormFields(id, getSubFormPrefixes(formPrefix, embeddedForms), (key, value) => {
            const previousValue = getIn(values, key, value);
            setElementValue(key, previousValue);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [embeddedForms]);

    useImperativeHandle(ref, () => ({
        handleEmbeddedFormValues() {
            findSubFormFields(id, getSubFormPrefixes(formPrefix, embeddedForms), onFieldChange);
        },
        submit() {
            innerRef.current.submit();
        },
    }), [id, onFieldChange, embeddedForms, formPrefix, innerRef]);

    const handleSubmit = async (values, isApply) => {
        findSubFormFields(id, getSubFormPrefixes(formPrefix, embeddedForms), (key, value) => {
            values = setIn(values, key, value);
        });
        if (typeof onSubmit === 'function') {
            // eslint-disable-next-line require-atomic-updates
            values = await onSubmit(values, isApply);
        }
        if (!values) {
            return;
        }

        setStatusMessages([]);
        setErrors({});

        const formState = isApply ? 'apply' : 'submit';
        setState(formState);

        if (mutation) {
            try {
                const { data } = await mutation({ variables: { input: values } });
                if (typeof onSuccess === 'function') {
                    onSuccess(data, isApply);
                }
            } catch ({ graphQLErrors }) {
                if (graphQLErrors[0].extensions.category === 'validate') {
                    const errors = graphQLErrors[0].extensions.messages;
                    setErrors(errors);
                    if (typeof onError === 'function') {
                        onError(errors);
                    }
                } else {
                    setStatusMessages([{
                        status: 'error',
                        content: graphQLErrors[0].message,
                    }]);
                }
            } finally {
                setState(null);
            }
            return;
        }

        try {
            handleSubmitSuccess(await api.post(action || window.location.href, toFormData(values)), formState);
        } catch (e) {
            setState(null);
            showInternalError(e);
        }
    };

    const handleSubmitSuccess = (response, formState) => {
        if (response.componentType === 'Jsw.Task.ProgressBar.Item' && typeof onLongTask === 'function') {
            onLongTask(response);
            return;
        }
        const isApply = formState === 'apply';

        const { status, redirect: url, postData, target, formMessages, statusMessages = [] } = response;
        if (url) {
            if (typeof onSuccess === 'function') {
                onSuccess();
            }

            if (isApply) {
                const { pathname } = window.location;
                if (isMatch(pathname)) {
                    history.replace(pathname, { reload: true });
                    setState(null);
                } else {
                    document.location.reload();
                }
            } else if (postData) {
                redirectPost(url, postData, target);
            } else {
                isMatch(url) ? history.push(url) : redirect(url, null, target);
            }
        } else {
            setState(null);
            setStatusMessages(statusMessages);
            setErrors(formMessages);
            if (!formMessages && status !== 'error' && typeof onSuccess === 'function') {
                onSuccess(response, isApply);
            }
            if (formMessages && typeof onError === 'function') {
                onError(formMessages);
            }
        }
    };

    const renderEmbeddedForms = () => embeddedForms.map(({ name, content }) => (
        <JswComponent
            key={name}
            id={`embedded-form-${name}`}
        >
            {content}
        </JswComponent>
    ));

    return (
        <BaseForm
            {...props}
            ref={innerRef}
            id={id}
            values={values}
            onFieldChange={onFieldChange}
            onSubmit={handleSubmit}
            errors={{ ...errors, ...props.errors }}
            state={state}
        >
            {statusMessages.map(({ status, content, title }) => (
                <StatusMessage intent={status === 'error' ? 'danger' : 'success'} key={content}>
                    {title ? <><b>{title}{':'}</b>{' '}</> : null}
                    {content}
                </StatusMessage>
            ))}
            {children}
            {embeddedFormsRender ? embeddedFormsRender(renderEmbeddedForms()) : renderEmbeddedForms()}
        </BaseForm>
    );
});

Form.propTypes = {
    children: PropTypes.node,
    id: PropTypes.string,
    action: PropTypes.string,
    mutation: PropTypes.func,
    values: PropTypes.object,
    onFieldChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onError: PropTypes.func,
    onSuccess: PropTypes.func,
    onLongTask: PropTypes.func,
    embeddedForms: PropTypes.array,
    formPrefix: PropTypes.string,
    embeddedFormsRender: PropTypes.func,
    errors: PropTypes.object,
};

Form.defaultProps = {
    children: null,
    id: undefined,
    action: undefined,
    mutation: undefined,
    values: undefined,
    onFieldChange: undefined,
    onSubmit: undefined,
    onError: undefined,
    onSuccess: undefined,
    onLongTask: undefined,
    embeddedForms: [],
    formPrefix: '',
    embeddedFormsRender: undefined,
    errors: {},
};

Form.displayName = 'Form';

export default Form;
